import { Vector, Vector2 } from "three";
import { GetDecimal } from "../Math";
import { lerp } from "three/src/math/MathUtils";

export default class AnimationTimeline {

  private tension: number;
  private resolution: number;

  private points: number[];
  private pointsLookup: Float32Array;

  private dataPointCount: number;

  constructor(point: number[], tension: number, resolution: number) {
    this.points = point;
    this.tension = tension;
    this.resolution = resolution;
    this.dataPointCount = (this.points.length - 2) / 2 * resolution;

    const pointArrayLength = this.points.length;
    this.pointsLookup = new Float32Array((pointArrayLength - 2) * this.resolution + 2);

    // Clone the points collection so that maniplutation doesnt affect the original data
    let pts = this.points.slice(0);
    pts.unshift(this.points[1]);
    pts.unshift(this.points[0]);
    pts.push(this.points[pointArrayLength - 2], this.points[pointArrayLength - 1]);

    // Cache inner-loop calculations as they are based on t alone
    const cache = new Float32Array((this.resolution + 2) * 4);
    let cachePtr = 4;
    cache[0] = 1;								// 1,0,0,0
    for (let i = 1; i < this.resolution; i++) {

      var st = i / this.resolution,
        st2 = st * st,
        st3 = st2 * st,
        st23 = st3 * 2,
        st32 = st2 * 3;

      cache[cachePtr++] = st23 - st32 + 1;	// c1
      cache[cachePtr++] = st32 - st23;		// c2
      cache[cachePtr++] = st3 - 2 * st2 + st;	// c3
      cache[cachePtr++] = st3 - st2;			// c4
    }

    cache[++cachePtr] = 1;						// 0,1,0,0

    // Calculate cuve points
    let rPos = 0;
    for (var i = 2, t; i < pointArrayLength; i += 2) {

      var pt1 = pts[i],
        pt2 = pts[i + 1],
        pt3 = pts[i + 2],
        pt4 = pts[i + 3],

        t1x = (pt3 - pts[i - 2]) * this.tension,
        t1y = (pt4 - pts[i - 1]) * this.tension,
        t2x = (pts[i + 4] - pt1) * this.tension,
        t2y = (pts[i + 5] - pt2) * this.tension;

      for (t = 0; t < this.resolution; t++) {

        var c = t << 2, //t * 4;

          c1 = cache[c],
          c2 = cache[c + 1],
          c3 = cache[c + 2],
          c4 = cache[c + 3];

        this.pointsLookup[rPos++] = c1 * pt1 + c2 * pt3 + c3 * t1x + c4 * t2x;
        this.pointsLookup[rPos++] = c1 * pt2 + c2 * pt4 + c3 * t1y + c4 * t2y;
      }
    }

    this.pointsLookup[rPos++] = this.points[pointArrayLength - 2];
    this.pointsLookup[rPos] = this.points[pointArrayLength - 1];
  }

  public readonly GetPosition = (t: number): number => {
    const pointIndex = this.dataPointCount * t;
    const leftIndex = Math.trunc(pointIndex) * 2;
    const leftPoint = this.pointsLookup[leftIndex + 1];
    return t >= 1 ? leftPoint : lerp(leftPoint, this.pointsLookup[leftIndex + 3], GetDecimal(pointIndex));
  }
}
