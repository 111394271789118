
export abstract class GameElement {

  protected scene: THREE.Scene;
  public get Scene() { return this.scene; }

  constructor(scene: THREE.Scene) {
    this.scene = scene;
  }

  public abstract readonly DoUpdate: (delta: number) => void;
}