import AnimationTimeline from "@/Helpers/Animation/AnimationTimeline";
import { Vector3 } from "three";
import { lerp } from "three/src/math/MathUtils";
import { CardLocation, PlayingCard } from "../PlayingCard";
import { CardModifier, ICardModifierOutput } from "./CardModifier";

const elevationTimeline = new AnimationTimeline([0, 0, 1, 1, 2, 0.7, 3, 0], 0.5, 10);
const positionTimeline = new AnimationTimeline([0, 0, 1, 0.2, 2, 0.8, 3, 1], 0.5, 10);
const angleTimeline = new AnimationTimeline([0, 0, 1, 1, 2, 0.3, 3, 0], 0.5, 10);


export class HandPositionController extends CardModifier {

  private animationTimeline = 0;

  constructor(card: PlayingCard) {
    super(card);

  }

  public readonly Update = (delta: number) => {

    const output: ICardModifierOutput = {
      position: new Vector3(this.Card.CurrentPosition.x, 0, this.Card.CurrentPosition.z),
      rotation: new Vector3(0, 0, 0)
    };

    if (this.Card.TargetLocation === 'Hand') {
      this.Card.TargetPosition.x = this.Card.Game.Table.MousePosition.x;
      this.Card.TargetPosition.z = this.Card.Game.Table.MousePosition.z;
      this.Card.TargetPosition.y = 1;
    }

    if (this.Card.TargetLocation === 'Table') {
      this.Card.CurrentLocation = 'Table';
      this.Card.TargetPosition.y = 0;
    }

    this.Card.CurrentPosition.x = this.Card.TargetPosition.x;
    this.Card.CurrentPosition.z = this.Card.TargetPosition.z;

    if (!this.Card.CurrentPosition.equals(this.Card.TargetPosition) || (this.animationTimeline > 0 && this.animationTimeline < 1)) {
      this.animationTimeline += 1 * delta;

      // Animate position
      // const positionStep = positionTimeline.GetPosition(this.animationTimeline);
      output.position.x = this.Card.TargetPosition.x;
      output.position.z = this.Card.TargetPosition.z;
      output.position.y = this.Card.TargetPosition.y;

      // Animate elevation
      // const elevationStep = elevationTimeline.GetPosition(this.animationTimeline);
      // output.position.y = lerp(0, this.Card.CurrentPosition.distanceTo(this.Card.TargetPosition) / 4, elevationStep);

      // Animate rotation
      // const angleStep = angleTimeline.GetPosition(this.animationTimeline);
      // const targetZRotation = (this.Card.CurrentPosition.z - this.Card.TargetPosition.z) * 0.05;
      // const targetXRotation = (this.Card.TargetPosition.y - this.Card.CurrentPosition.y) * 0.05;
      // output.rotation.z = lerp(0, targetZRotation, angleStep);
      // output.rotation.x = lerp(0, targetXRotation, angleStep);

      // End animation
      // if (this.animationTimeline >= 1) {
      //   this.Card.CurrentPosition = this.Card.TargetPosition.clone();
      //   this.animationTimeline = 0;
      // }
    }
    return output;
  };
}