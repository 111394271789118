import { Vector2, Vector3 } from "three";
import { PlayingCard, CardLocation, FaceDirection } from "../PlayingCard";
import { CardModifier, ICardModifierOutput } from "./CardModifier";
import AnimationTimeline from "@/Helpers/Animation/AnimationTimeline";
import { lerp } from "three/src/math/MathUtils";

const elevationTimeline = new AnimationTimeline([0, 0, 1, 1, 2, 1, 3, 0], 0.5, 10);
const angleTimeline = new AnimationTimeline([0, 0, 3, 1], 0.5, 10);

export class TableFlipModifier extends CardModifier {

  private animationTimeline = 0;

  constructor(card: PlayingCard) {
    super(card);

    setInterval(() => {
      if (Math.random() * 20 < 19) { return; }
      this.Card.TargetFaceDirection = this.Card.TargetFaceDirection === 'Up' ? 'Down' : 'Up';
    }, 2000);
  }

  public readonly Update = (delta: number) => {

    const output: ICardModifierOutput = {
      position: new Vector3(0, 0, 0),
      rotation: new Vector3(0, 0, 0)
    };

    if (this.Card.CurrentLocation !== 'Table') { return output };

    if (this.Card.CurrentFaceDirection !== this.Card.TargetFaceDirection || (this.animationTimeline > 0 && this.animationTimeline < 1)) {
      this.animationTimeline += 2 * delta;

      // Animate elevation
      const elevationStep = elevationTimeline.GetPosition(this.animationTimeline);
      output.position.y = lerp(0, 2, elevationStep);

      // Animate rotation
      const rotateFrom = this.Card.CurrentFaceDirection === 'Up' ? 0 : 3.14159;
      const rotateTo = this.Card.CurrentFaceDirection === 'Up' ? 3.14159 : 0;
      const angleStep = angleTimeline.GetPosition(this.animationTimeline);
      output.rotation.z = lerp(rotateFrom, rotateTo, angleStep);
      // output.rotation.x = lerp(0, targetXRotation, angleStep);

      // End animation
      if (this.animationTimeline >= 1) {
        this.Card.CurrentFaceDirection = this.Card.TargetFaceDirection;
        this.animationTimeline = 0;
      }
    } else {
      output.rotation.z = this.Card.CurrentFaceDirection === 'Up' ? 0 : 3.14159;
    }
    return output;
  };
}