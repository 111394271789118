import ResourceLoader from "@/Helpers/ResourceLoader";
import { Group, Mesh, MeshStandardMaterial, Scene, Texture, Vector2, Vector3 } from "three";
import { GLTF } from "three/examples/jsm/loaders/GLTFLoader";
import { TablePositionController } from "./CardModifier/TablePositionController";
import { TableFlipModifier } from "./CardModifier/TableFlipModifier";
import { GameElement } from "./GameElement";
import { CardModifier } from "./CardModifier/CardModifier";
import Nimmt from "./Nimmt";
import cardFaceShader from "./Shaders/CardFaceShader";
import StandardDeck, { GetCardDefinition } from "./StandardPlayingCardMap";
import { HandPositionController } from "./CardModifier/HandPositionController";
import { CardParent } from "./TablePositions/CardParent";

export type CardLocation = 'Table' | 'Hand';

export type FaceDirection = 'Up' | 'Down';

export class PlayingCard extends GameElement {
  private testCounter = 0;

  private Owner: CardParent;

  public CurrentLocation: CardLocation = 'Table';
  public TargetLocation: CardLocation = 'Table';

  public CurrentFaceDirection: FaceDirection = 'Up';
  public TargetFaceDirection: FaceDirection = 'Up';

  public TargetPosition: Vector3 = new Vector3(0, 0, 0);
  public CurrentPosition: Vector3 = new Vector3(0, 0, 0);

  public readonly Modifiers: CardModifier[];

  private mesh!: Group;
  public get Mesh() { return this.mesh; }

  private isReady = false;
  public get IsReady() { return this.isReady; }

  public readonly Game: Nimmt;
  public readonly CardName: string;

  public readonly TablePositionController: TablePositionController;
  public readonly HandPositionController: HandPositionController;

  constructor(game: Nimmt, owner: CardParent, cardName: string) {
    super(game.Scene);

    this.Owner = owner;
    this.Owner.AddCard(this);
    // this.CurrentPosition = startPosition.clone();
    // this.TargetPosition = startPosition.clone();

    this.Game = game;
    this.CardName = cardName;
    ResourceLoader.GetMesh('PlayingCard').then(c => {
      this.mesh = c.scene.clone();
      this.scene.add(this.mesh);
      this.mesh.traverse(node => node.castShadow = true);
      this.mesh.traverse(node => node.receiveShadow = true);
      const faceMesh = this.mesh.children.filter(m => m.name === 'Face')[0] as Mesh;
      // faceMesh.material = cardFaceShader;
      const cardDefinition = GetCardDefinition(this.CardName);
      // ((faceMesh.material as MeshStandardMaterial).map as Texture).offset.x += (cardDefinition.textureX) / 8;
      // ((faceMesh.material as MeshStandardMaterial).map as Texture).offset.y += (cardDefinition.textureY) / 11.4;
    });

    this.TablePositionController = new TablePositionController(this);
    this.HandPositionController = new HandPositionController(this);
    this.Modifiers = [
      new TableFlipModifier(this),
    ];
  }

  public readonly DoUpdate = (delta: number) => {
    if (this.Mesh) {
      // // const primaryPosition = this.doPrimaryUpdate(delta);

      // // this.Modifiers.forEach(m => {
      // //   const modifier = m.Update(delta);
      // //   primaryPosition.position.add(modifier.position);
      // //   primaryPosition.rotation.add(modifier.rotation);
      // // });

      // // this.mesh.position.set(primaryPosition.position.x, primaryPosition.position.y, primaryPosition.position.z);
      // // this.mesh.rotation.set(primaryPosition.rotation.x, primaryPosition.rotation.y, primaryPosition.rotation.z);
      const targetTransform = this.Owner.GetTransform(this);
      this.mesh.position.set(targetTransform.position.x, targetTransform.position.y, targetTransform.position.z);
      this.mesh.rotation.set(targetTransform.rotation.x, targetTransform.rotation.y, targetTransform.rotation.z);
    }
  }

  private readonly doPrimaryUpdate = (delta: number) => {
    if (this.CurrentLocation == 'Hand' || this.TargetLocation == 'Hand') {
      return this.HandPositionController.Update(delta);
    }

    return this.TablePositionController.Update(delta);
  }
}