
export function RoundToDp(value: number, decimals: number) {
  return Math.round(value * decimals) / decimals;
}

export function getDistance(x1: number, y1: number, x2: number, y2: number) {
  let y = x2 - x1;
  let x = y2 - y1;

  return Math.sqrt(x * x + y * y);
}

export function Normalise(min: number, max: number, value: number, newMin: number = 0, newMax: number = 1) {
  return Math.max(min, Math.min(max, newMin + (value - min) * (newMax - newMin) / (max - min)));
}

export function GetDecimal(value: number) {
  return value - Math.trunc(value);
}