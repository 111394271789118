import { Vector3 } from "three";
import { PlayingCard } from "../PlayingCard";

export abstract class CardParent {
  public readonly Cards: PlayingCard[] = [];

  constructor() {
  }

  public readonly AddCard = (card: PlayingCard) => {
    this.Cards.push();
  }

  public abstract GetTransform: (card: PlayingCard) => ICardTransform;
}

export interface ICardTransform {
  position: Vector3;
  rotation: Vector3;
}