import { Vector3 } from "three";
import { PlayingCard } from "../PlayingCard";

export abstract class CardModifier {

  public readonly Card: PlayingCard;

  constructor(card: PlayingCard) {
    this.Card = card;
  }

  public abstract readonly Update: (delta: number) => ICardModifierOutput;
}

export interface ICardModifierOutput {
  position: Vector3;
  rotation: Vector3;
}