import { Vector3 } from "three";
import { PlayingCard } from "../PlayingCard";
import { CardParent } from "./CardParent";
import { lerp } from "three/src/math/MathUtils";

export type SpreadAlignment = 'Start' | 'Center' | 'End';
export type SpreadDirection = 'Vertical' | 'Horizontal';

export class CardSpread extends CardParent {

  private sortingRule: (a: PlayingCard, b: PlayingCard) => -1 | 0 | 1;
  public readonly Spacing: number;
  public readonly Direction: SpreadDirection;
  public readonly Alignment: SpreadAlignment;
  public readonly Position: Vector3;

  constructor(position: Vector3, alignment: SpreadAlignment, direction: SpreadDirection, spacing = 2, sortingRule: (a: PlayingCard, b: PlayingCard) => -1 | 0 | 1 = (a, b) => 0) {
    super();
    this.sortingRule = sortingRule;
    this.Position = position;
    this.Direction = direction;
    this.Alignment = alignment;
    this.Spacing = spacing;
  }

  public readonly AddCard = (card: PlayingCard) => {
    this.Cards.push(card);
    this.Cards.sort(this.sortingRule);
  }

  public readonly GetTransform = (card: PlayingCard) => {
    const index = this.Cards.indexOf(card);
    const width = this.Cards.length * 2.5 * this.Spacing;
    const left = this.Alignment === 'Center' ? this.Position.x - width / 2 : (this.Alignment === 'Start' ? this.Position.x : this.Position.x - width);
    const rotation = this.Spacing < 1 && index > 0 ? new Vector3(0, 0, lerp(-0.015, -0.005, this.Spacing) * index) : new Vector3(0, 0, 0);
    const elevation = this.Spacing < 1 && index > 0 ? this.Position.y + (0.01 * index) : this.Position.y;
    return { position: new Vector3(left + (index * 2.5 * this.Spacing), elevation, this.Position.z), rotation };
  }
}