
export type StandardPlayingCardSuit = 'Clubs' | 'Diamonds' | 'Hearts' | 'Spades';
export type StandardPlayingCardColour = 'Black' | 'Red';

export interface IStandardPlayingCardMap {
  deck: IStandardPlayingCard[];
}

export interface IStandardPlayingCard {
  cardName: string;
  value: number;
  suite: StandardPlayingCardSuit;
  color: StandardPlayingCardColour;
  textureX: number;
  textureY: number;
}

export function GetCardDefinition(name: string) { return StandardDeck.get(name) as IStandardPlayingCard; }

const StandardDeck = new Map<string, IStandardPlayingCard>([
  ['C1', { cardName: 'Ace of clubs', suite: 'Clubs', color: 'Black', value: 1, textureX: 0, textureY: 0 }],
  ['C2', { cardName: 'Two of clubs', suite: 'Clubs', color: 'Black', value: 2, textureX: 1, textureY: 0 }],
  ['C3', { cardName: 'Three of clubs', suite: 'Clubs', color: 'Black', value: 3, textureX: 2, textureY: 0 }],
  ['C4', { cardName: 'Four of clubs', suite: 'Clubs', color: 'Black', value: 4, textureX: 3, textureY: 0 }],
  ['C5', { cardName: 'Five of clubs', suite: 'Clubs', color: 'Black', value: 5, textureX: 4, textureY: 0 }],
  ['C6', { cardName: 'Six of clubs', suite: 'Clubs', color: 'Black', value: 6, textureX: 5, textureY: 0 }],
  ['C7', { cardName: 'Seven of clubs', suite: 'Clubs', color: 'Black', value: 7, textureX: 6, textureY: 0 }],
  ['C8', { cardName: 'Eight of clubs', suite: 'Clubs', color: 'Black', value: 8, textureX: 7, textureY: 0 }],
  ['C9', { cardName: 'Nine of clubs', suite: 'Clubs', color: 'Black', value: 9, textureX: 0, textureY: 1 }],
  ['C10', { cardName: 'Ten of clubs', suite: 'Clubs', color: 'Black', value: 10, textureX: 1, textureY: 1 }],
  ['C11', { cardName: 'Jack of clubs', suite: 'Clubs', color: 'Black', value: 11, textureX: 2, textureY: 1 }],
  ['C12', { cardName: 'Queen of clubs', suite: 'Clubs', color: 'Black', value: 12, textureX: 3, textureY: 1 }],
  ['C13', { cardName: 'King of clubs', suite: 'Clubs', color: 'Black', value: 13, textureX: 4, textureY: 1 }],
  ['D1', { cardName: 'Ace of diamonds', suite: 'Diamonds', color: 'Red', value: 1, textureX: 0, textureY: 2 }],
  ['D2', { cardName: 'Two of diamonds', suite: 'Diamonds', color: 'Red', value: 2, textureX: 1, textureY: 2 }],
  ['D3', { cardName: 'Three of diamonds', suite: 'Diamonds', color: 'Red', value: 3, textureX: 2, textureY: 2 }],
  ['D4', { cardName: 'Four of diamonds', suite: 'Diamonds', color: 'Red', value: 4, textureX: 3, textureY: 2 }],
  ['D5', { cardName: 'Five of diamonds', suite: 'Diamonds', color: 'Red', value: 5, textureX: 4, textureY: 2 }],
  ['D6', { cardName: 'Six of diamonds', suite: 'Diamonds', color: 'Red', value: 6, textureX: 5, textureY: 2 }],
  ['D7', { cardName: 'Seven of diamonds', suite: 'Diamonds', color: 'Red', value: 7, textureX: 6, textureY: 2 }],
  ['D8', { cardName: 'Eight of diamonds', suite: 'Diamonds', color: 'Red', value: 8, textureX: 7, textureY: 2 }],
  ['D9', { cardName: 'Nine of diamonds', suite: 'Diamonds', color: 'Red', value: 9, textureX: 0, textureY: 3 }],
  ['D10', { cardName: 'Ten of diamonds', suite: 'Diamonds', color: 'Red', value: 10, textureX: 1, textureY: 3 }],
  ['D11', { cardName: 'Jack of diamonds', suite: 'Diamonds', color: 'Red', value: 11, textureX: 2, textureY: 3 }],
  ['D12', { cardName: 'Queen of diamonds', suite: 'Diamonds', color: 'Red', value: 12, textureX: 3, textureY: 3 }],
  ['D13', { cardName: 'King of diamonds', suite: 'Diamonds', color: 'Red', value: 13, textureX: 4, textureY: 3 }],
  ['S1', { cardName: 'Ace of spades', suite: 'Spades', color: 'Black', value: 1, textureX: 0, textureY: 4 }],
  ['S2', { cardName: 'Two of spades', suite: 'Spades', color: 'Black', value: 2, textureX: 1, textureY: 4 }],
  ['S3', { cardName: 'Three of spades', suite: 'Spades', color: 'Black', value: 3, textureX: 2, textureY: 4 }],
  ['S4', { cardName: 'Four of spades', suite: 'Spades', color: 'Black', value: 4, textureX: 3, textureY: 4 }],
  ['S5', { cardName: 'Five of spades', suite: 'Spades', color: 'Black', value: 5, textureX: 4, textureY: 4 }],
  ['S6', { cardName: 'Six of spades', suite: 'Spades', color: 'Black', value: 6, textureX: 5, textureY: 4 }],
  ['S7', { cardName: 'Seven of spades', suite: 'Spades', color: 'Black', value: 7, textureX: 6, textureY: 4 }],
  ['S8', { cardName: 'Eight of spades', suite: 'Spades', color: 'Black', value: 8, textureX: 7, textureY: 4 }],
  ['S9', { cardName: 'Nine of spades', suite: 'Spades', color: 'Black', value: 9, textureX: 0, textureY: 5 }],
  ['S10', { cardName: 'Ten of spades', suite: 'Spades', color: 'Black', value: 10, textureX: 1, textureY: 5 }],
  ['S11', { cardName: 'Jack of spades', suite: 'Spades', color: 'Black', value: 11, textureX: 2, textureY: 5 }],
  ['S12', { cardName: 'Queen of spades', suite: 'Spades', color: 'Black', value: 12, textureX: 3, textureY: 5 }],
  ['S13', { cardName: 'King of spades', suite: 'Spades', color: 'Black', value: 13, textureX: 4, textureY: 5 }],
  ['H1', { cardName: 'Ace of hearts', suite: 'Hearts', color: 'Red', value: 1, textureX: 0, textureY: 6 }],
  ['H2', { cardName: 'Two of hearts', suite: 'Hearts', color: 'Red', value: 2, textureX: 1, textureY: 6 }],
  ['H3', { cardName: 'Three of hearts', suite: 'Hearts', color: 'Red', value: 3, textureX: 2, textureY: 6 }],
  ['H4', { cardName: 'Four of hearts', suite: 'Hearts', color: 'Red', value: 4, textureX: 3, textureY: 6 }],
  ['H5', { cardName: 'Five of hearts', suite: 'Hearts', color: 'Red', value: 5, textureX: 4, textureY: 6 }],
  ['H6', { cardName: 'Six of hearts', suite: 'Hearts', color: 'Red', value: 6, textureX: 5, textureY: 6 }],
  ['H7', { cardName: 'Seven of hearts', suite: 'Hearts', color: 'Red', value: 7, textureX: 6, textureY: 6 }],
  ['H8', { cardName: 'Eight of hearts', suite: 'Hearts', color: 'Red', value: 8, textureX: 7, textureY: 6 }],
  ['H9', { cardName: 'Nine of hearts', suite: 'Hearts', color: 'Red', value: 9, textureX: 0, textureY: 7 }],
  ['H10', { cardName: 'Ten of hearts', suite: 'Hearts', color: 'Red', value: 10, textureX: 1, textureY: 7 }],
  ['H11', { cardName: 'Jack of hearts', suite: 'Hearts', color: 'Red', value: 11, textureX: 2, textureY: 7 }],
  ['H12', { cardName: 'Queen of hearts', suite: 'Hearts', color: 'Red', value: 12, textureX: 3, textureY: 7 }],
  ['H13', { cardName: 'King of hearts', suite: 'Hearts', color: 'Red', value: 13, textureX: 4, textureY: 7 }],
]);

export default StandardDeck; 