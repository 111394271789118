<template>
  <router-view />
</template>

<style lang="scss">
html,
body,
#app {
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  overflow: hidden
  ;
}
</style>
