import { GLTF, GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';

class ResourceLoader {

  private gltfLoader = new GLTFLoader();
  private readonly namedMeshes = new Map<string, string>();

  public readonly GetMesh = (name: string) => {

    return new Promise<GLTF>((resolve, reject) => {
      if (!this.namedMeshes.has(name)) {
        reject(new Error(`An error occured loading model '${name}'.  Model is not registered.`))
      }
      this.gltfLoader.load(this.namedMeshes.get(name) as string, resolve,
        () => { },
        error => {
          console.error(`An error occured loading model '${name}'`, error);
          reject(error);
        }
      );
    });
  }

  public readonly RegisterNamedMesh = (name: string, path: string) => {
    this.namedMeshes.set(name, path);
  }
}

export default new ResourceLoader();

