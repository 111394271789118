import { BoxGeometry, Mesh, MeshStandardMaterial, PerspectiveCamera, Raycaster, Renderer, Vector2, Vector3 } from "three";
import { GameElement } from "./GameElement";
import Nimmt from "./Nimmt";
import * as THREE from 'three';

export class GameTable extends GameElement {

  public MousePosition: Vector3 = new Vector3(0, 0, 0);

  public readonly TableMesh: Mesh;

  constructor(game: Nimmt, renderer: Renderer, camera: PerspectiveCamera) {
    super(game.Scene);

    const setTextureScale = (texture: THREE.Texture) => {
      texture.wrapS = THREE.RepeatWrapping;
      texture.wrapT = THREE.RepeatWrapping;
      texture.repeat.set(24, 24);
      return texture;
    }

    const floorMap = setTextureScale(new THREE.TextureLoader().load("textures/fabric/pgphusp_2K_Albedo.jpg"));
    const floorAO = setTextureScale(new THREE.TextureLoader().load("textures/fabric/pgphusp_2K_AO.jpg"));
    const floorNormal = setTextureScale(new THREE.TextureLoader().load("textures/fabric/pgphusp_2K_Normal"));
    const floorRoughness = setTextureScale(new THREE.TextureLoader().load("textures/fabric/pgphusp_2K_Roughness.jpg"));

    const floorMaterial = new THREE.MeshStandardMaterial({ aoMap: floorAO, map: floorMap, normalMap: floorNormal, roughnessMap: floorRoughness });
    // const floorMaterial = new MeshStandardMaterial({ color: 0x096836 });

    this.TableMesh = new Mesh(new BoxGeometry(50, 0.2, 50), floorMaterial);
    this.TableMesh.position.y = -0.20;
    this.TableMesh.receiveShadow = true;
    this.Scene.add(this.TableMesh);

    const cursorRaycaster = new Raycaster();

    renderer.domElement.addEventListener('mousemove', event => {
      const mousePosition = new Vector2((event.clientX / window.innerWidth) * 2 - 1, -(event.clientY / window.innerHeight) * 2 + 1);

      cursorRaycaster.setFromCamera(mousePosition, camera);

      var intersects = cursorRaycaster.intersectObject(this.TableMesh, true);
      if (intersects.length > 0) {
        this.MousePosition = intersects[0].point;
      }
    });
  }

  public DoUpdate = (delta: number) => { };

}