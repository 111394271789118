import * as THREE from "three";
import { GameElement } from "./GameElement";
import { CardLocation, PlayingCard } from "./PlayingCard";
import { GameTable } from "./GameTable";
import { CardSpread } from "./TablePositions/CardSpread";

export default class Nimmt extends GameElement {

  public readonly Cards: PlayingCard[] = [];
  public readonly Table: GameTable;

  public PlayersHand = new CardSpread(new THREE.Vector3(0, 0, 5), 'Center', 'Horizontal', 1.4);
  public Row1 = new CardSpread(new THREE.Vector3(-14, 0, -17), 'Start', 'Horizontal', 1.4);
  public Row2 = new CardSpread(new THREE.Vector3(-14, 0, -12), 'Start', 'Horizontal', 0.4);
  public Row3 = new CardSpread(new THREE.Vector3(-14, 0, -7), 'Start', 'Horizontal', 1.4);
  public Row4 = new CardSpread(new THREE.Vector3(-14, 0, -2), 'Start', 'Horizontal', 1.4);

  constructor(scene: THREE.Scene, renderer: THREE.Renderer, camera: THREE.PerspectiveCamera) {
    super(scene);

    this.Table = new GameTable(this, renderer, camera);
    const cursorRaycaster = new THREE.Raycaster();

    for (let i = 1; i <= 10; i++) {
      const card = new PlayingCard(this, this.PlayersHand, `${i}`);
      this.Cards.push(card);
    }

    this.Cards.push(new PlayingCard(this, this.Row1, `11`));
    this.Cards.push(new PlayingCard(this, this.Row1, `15`));
    this.Cards.push(new PlayingCard(this, this.Row1, `16`));
    this.Cards.push(new PlayingCard(this, this.Row1, `17`));
    this.Cards.push(new PlayingCard(this, this.Row1, `18`));
    this.Cards.push(new PlayingCard(this, this.Row2, `12`));
    this.Cards.push(new PlayingCard(this, this.Row3, `13`));
    this.Cards.push(new PlayingCard(this, this.Row4, `14`));

    this.Cards.push(new PlayingCard(this, this.Row2, `11`));
    this.Cards.push(new PlayingCard(this, this.Row2, `15`));
    this.Cards.push(new PlayingCard(this, this.Row2, `16`));
    this.Cards.push(new PlayingCard(this, this.Row2, `17`));
    this.Cards.push(new PlayingCard(this, this.Row2, `18`));
    this.Cards.push(new PlayingCard(this, this.Row2, `12`));
    this.Cards.push(new PlayingCard(this, this.Row2, `13`));
    this.Cards.push(new PlayingCard(this, this.Row2, `14`));
    this.Cards.push(new PlayingCard(this, this.Row2, `18`));
    this.Cards.push(new PlayingCard(this, this.Row2, `12`));
    this.Cards.push(new PlayingCard(this, this.Row2, `13`));
    this.Cards.push(new PlayingCard(this, this.Row2, `14`));

    renderer.domElement.addEventListener('mousemove', event => {
      const mousePosition = new THREE.Vector2((event.clientX / window.innerWidth) * 2 - 1, -(event.clientY / window.innerHeight) * 2 + 1);

      cursorRaycaster.setFromCamera(mousePosition, camera);
      this.Cards.forEach(c => {
        var intersects = cursorRaycaster.intersectObject(c.Mesh, true);
        if (intersects.length > 0) {
          console.log(c.CardName);
        }
      });
    });

    renderer.domElement.addEventListener('mousedown', event => {
      for (let card of this.Cards) {
        var intersects = cursorRaycaster.intersectObject(card.Mesh, true);
        if (intersects.length > 0) {
          card.TargetLocation = 'Hand';
          console.log(`Card ${card.CardName} in hand`)
          return;
        }
      }
    });

    renderer.domElement.addEventListener('mouseup', event => {
      for (let card of this.Cards) {

        if (card.TargetLocation == 'Hand' || card.CurrentLocation == 'Hand') {
          card.TargetLocation = 'Table';
          console.log(`Drop card ${card.CardName} onto table`)
        }
      }
    });
  }


  public readonly DoUpdate = (delta: number) => {
    this.Cards.forEach(c => c.DoUpdate(delta));
  };
}

function shuffle(array: PlayingCard[]) {
  var m = array.length, t, i;
  while (m) {
    i = Math.floor(Math.random() * m--);
    t = array[m];
    array[m] = array[i];
    array[i] = t;
  }
}